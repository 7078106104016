<template>
  <div class="wp" v-html="htmlText"></div>
</template>
<script>
import Axios from "axios";
export default {
  data() {
    return {
      htmlText: "",
      url: this.env.srvHost + "/web",
    };
  },
  mounted() {
    let my = this;
    let xwId = this.$route.params.newsId;
    Axios.get(my.url + "/zmk/wy", {
      params: {
        portal: my.env.portal,
        wyid: xwId,
      },
    })
      .then(function (resp) {
        my.showList = false;
        let data = resp.data;
        if (data.length > 0) {
          my.htmlText = data[0].WYNR;
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
<style scoped>
.wp {
  /* background: #fbfdfd; */
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
</style>